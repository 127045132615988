export const DevExtremeLanguageCodes = {
  ['ca-es' as string]: 'ca',
  ['cs-cz' as string]: 'cs',
  ['de-de' as string]: 'de',
  ['de-at' as string]: 'de',
  ['de-ch' as string]: 'de',
  ['en-au' as string]: 'en',
  ['en-ca' as string]: 'en',
  ['en-in' as string]: 'en',
  ['en-ie' as string]: 'en',
  ['en-my' as string]: 'en',
  ['en-nz' as string]: 'en',
  ['en-sg' as string]: 'en',
  ['en-za' as string]: 'en',
  ['en-gb' as string]: 'en',
  ['en-us' as string]: 'en',
  ['es-es' as string]: 'es',
  ['es-mx' as string]: 'es',
  ['fil-ph' as string]: 'fi',
  ['fr-fr' as string]: 'fr',
  ['fr-be' as string]: 'fr',
  ['fr-ca' as string]: 'fr',
  ['fr-ch' as string]: 'fr',
  ['ga-ie' as string]: 'en',
  ['it-it' as string]: 'it',
  ['it-ch' as string]: 'it',
  ['lv-lv' as string]: 'lv',
  ['lt-lt' as string]: 'lt',
  ['hu-hu' as string]: 'hu',
  ['nl-nl' as string]: 'nl',
  ['nl-be' as string]: 'nl',
  ['nb-no' as string]: 'nb',
  ['pl-pl' as string]: 'pl',
  ['pt-br' as string]: 'pt',
  ['pt-pt' as string]: 'pt',
  ['ro-ro' as string]: 'ro',
  ['sl-si' as string]: 'sl',
  ['fi-fi' as string]: 'fi',
  ['sv-se' as string]: 'sv',
  ['vi-vn' as string]: 'vi',
  ['tr-tr' as string]: 'tr',
  ['ar-sa' as string]: 'ar',
  ['ru-ru' as string]: 'ru',
  ['zh-cn' as string]: 'zh',
  ['zh-tw' as string]: 'zh-tw',
  ['zh-hk' as string]: 'zh',
  ['ja-jp' as string]: 'ja',
  ['mk-mk' as string]: 'mk'
} as const;

import { FC } from 'react';
import { endOfDay, startOfDay } from 'date-fns';

import DateRangeFilters from './DateRangeFilters';

type Props = {
  value: { from: Date; to: Date } | undefined;
  onChange: (data: { from: Date; to: Date }) => void;
};

export const DateRangeFormFilters: FC<Props> = ({ value, onChange }) => (
  <DateRangeFilters
    selectedDateRange={value ? [value?.from, value?.to] : [startOfDay(new Date()), endOfDay(new Date())]}
    onDateRangeChange={(v) => onChange({ from: v[0], to: v[1] })}
  />
);
